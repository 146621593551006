"use client";

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import Link from 'next/link';
import styles from "../styles/sliders.module.css";
import Image from 'next/image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faNewspaper, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import "../styles/slider.css"; 


export default function NewsSlider({ newsData }) {

  return (
    <>
      <div className={styles.gamesMore}>
        <FontAwesomeIcon icon={faNewspaper} style={{ width: '15px', height: '15px' }}/>
        <h2>Новости</h2>
      </div>

      <div className={styles.customSliderContainer}>
        <div className={styles.newsPrev}>
          <div className={styles.customArrowContainer}>
            <FontAwesomeIcon icon={faChevronLeft} style={{ width: '7.5px', height: '12px' }}/>
          </div>
        </div>
        <div className={styles.newsNext}>
          <div className={styles.customArrowContainer}>
            <FontAwesomeIcon icon={faChevronRight} style={{ width: '7.5px', height: '12px' }}/>
          </div>
        </div>

        <Swiper
          modules={[Navigation]}
          navigation={{
            prevEl: `.${styles.newsPrev}`,
            nextEl: `.${styles.newsNext}`,
          }}
          spaceBetween={16}
          slidesPerView="auto"
        >
          {newsData.map((slide, index) => (
            <SwiperSlide key={slide.id}>
              <Link href={slide.url}>
                <div className={styles.customSlideItem}>
                    <Image
                        fill
                        src={slide.imageUrl}
                        alt={`Обложка ${slide.alt}`}
                        quality={75}
                        style={{ objectFit: "cover" }}
                      />
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};
